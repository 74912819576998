body {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.rootContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
}
